import React from "react"
import { Container, Row, Col } from "reactstrap"

import landing_screenImage from "assets/images/landing_screen.jpg"
import landing_vanImage from "assets/images/landing_van.jpg"
import landing_fishingImage from "assets/images/landing_fishing.jpg"

export default function Landing() {
  return (
    <Container className="my-100">
      <Row className="align-items-center gx-50 gx-lg-80 gx-xl-100 gy-30" style={{ marginBottom: "35px" }}>
        <Col md={6}>
          <img src={landing_fishingImage} className="w-100" alt="fishing" />
        </Col>
        <Col md={6}>
          <h4 className="fw-medium lh-1 mb-30" style={{ color: "#0052B4", lineHeight: "51px", fontSize: "24px" }}>
            Seamless Booking Made Simple
          </h4>
          <div style={{ color: "rgba(20, 20, 20, 0.9)" }}>
            <p>
              Effortlessly manage your reservations with Digital Sportsman’s robust booking platform. Designed for trips, rentals, and
              ticketing, our system streamlines the process for you and your clients.
            </p>
            <p>
              With flexible payment options, including credit cards, cash, and split payments for group bookings, you can provide a seamless
              experience that keeps customers coming back.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center gx-50 gx-lg-80 gx-xl-100 gy-30" style={{ marginBottom: "35px", marginTop: "35px" }}>
        <Col md={6} className="order-2 order-md-1">
          <h4 className="fw-medium lh-1 mb-30" style={{ color: "#0052B4", lineHeight: "51px", fontSize: "24px" }}>
            Powerful Business Tools​
          </h4>
          <p style={{ color: "rgba(20, 20, 20, 0.9)" }}>
            Take your guiding business to the next level with a suite of tools designed to streamline operations and drive growth.
          </p>
          <div style={{ color: "rgba(20, 20, 20, 0.9)" }}>
            <p>
              <strong>AI Assistant:</strong> Quickly create optimized profiles and listings that showcase your services to attract more
              clients.
            </p>
            <p>
              <strong>Resource & Inventory Management:</strong> Easily track availability, manage waivers, and ensure every trip is ready to
              go with intuitive app-based tools.
            </p>
            <p>
              <strong>Financial Insights:</strong> Stay on top of your business with advanced expense tracking and dashboards that provide a
              clear view of your performance.
            </p>
            <p>
              <strong>AI-Driven Social Media Posting:</strong> Save time and boost your online visibility with automated social media
              updates tailored to engage your audience.
            </p>
            <p>
              <strong>One-Touch Sharing:</strong> Share trip photos and updates with clients in seconds, keeping them engaged and
              encouraging repeat bookings.
            </p>
          </div>
        </Col>
        <Col md={6} className="d-flex justify-content-center order-1 order-md-2">
          <img src={landing_screenImage} className="w-100" alt="screen" />
        </Col>
      </Row>
      <Row className="align-items-center gx-50 gx-lg-80 gx-xl-100 gy-30" style={{ marginTop: "35px" }}>
        <Col md={6} className="d-flex justify-content-center">
          <img src={landing_vanImage} className="w-100" alt="van" />
        </Col>
        <Col md={6}>
          <h4 className="fw-medium lh-1 mb-30" style={{ color: "#0052B4", lineHeight: "51px", fontSize: "24px" }}>
            Enhanced Guest Experience​
          </h4>
          <p style={{ color: "rgba(20, 20, 20, 0.9)" }}>
            <strong>Native Guest App:</strong> Simplify the booking process with an easy-to-use app that enables direct communication with
            clients.
          </p>
          <p style={{ color: "rgba(20, 20, 20, 0.9)" }}>
            <strong>Verified & Reviewed Guides:</strong> Build trust and confidence by showcasing verified profiles and authentic reviews.
          </p>
          <p style={{ color: "rgba(20, 20, 20, 0.9)" }}>
            <strong>Seamless Access:</strong> Provide guests with instant access to trip photos, booking confirmations, and real-time
            updates, all in one place.
          </p>
        </Col>
      </Row>
    </Container>
  )
}
