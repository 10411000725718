import React from "react"
import { Container, Row, Col } from "reactstrap"

import automation from "assets/icons/landing_page/automation.svg"
import dollar from "assets/icons/landing_page/dollar.svg"
import favourite from "assets/icons/landing_page/favourite.svg"

export default function Advantage() {
  return (
      <div className="position-relative overflow-hidden py-40 py-sm-70" style={{ background: 'rgba(0, 82, 180, 0.04)' }}>
        <Container className="vstack align-items-center">
          <h1 className="fw-semibold lh-1 text-center m-20 mb-sm-40" >Discover the<br/> 
          Digital Sportsman Advantage</h1>
          <Row className="justify-content-between align-items-start w-100">
            <Col xs={12} md={4} className="p-2 d-flex align-items-start justify-content-center">
              <img src={dollar} className="me-2" alt="dollarImg" width = "30px" />
              <div>
                <h3 className= "fs-3 fw-medium">Seamless<br /> Booking</h3>
              </div>
            </Col>
            <Col xs={12} md={4} className="p-2 d-flex align-items-start justify-content-center">
              <img src={automation} className="me-2" alt="automationImg" width = "33px" />
              <div>
                <h3 className= "fs-3 fw-medium">Powerful<br /> Business Tools</h3>
              </div>
            </Col>
            <Col xs={12} md={4} className="p-2 d-flex align-items-start justify-content-center">
              <img src={favourite} className="me-2" alt="favouriteImg" width = "30px" />
              <div>
                <h3 className = "fs-3 fw-medium">Exceptional<br /> Experiences</h3>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
  )
}
